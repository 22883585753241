* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    background-color: #303952;
    color: #f7d794;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    padding-top: 150px;
}

@media screen and (min-width: 768px) {
    body {
        padding-top: 170px;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}